<template>
    <div class="clue-statistics-child">
        <div class="clue-statistics-box-child">
            <h1 class="day-trend-head">
                <span></span>
                <span>月变化趋势图</span>
                <span class="iconfont" @click="closeClueStatistics">&#xe67d;</span>
            </h1>
            <div style="padding:.2rem">
                <div class="clue-statistics-one">
                    <h1>线索跟踪成功率</h1>
                    <div style="width:100%;height:240px;" id="clueStatisticsChildId"></div>
                </div>
                <div class="clue-statistics-two">
                    <el-table
                        :data="tableData"
                        border
                        style="width: 100%"
                        show-summary
                    >
                        <el-table-column
                            prop="name"
                            label="业务员名称"
                            width="180"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="xsts"
                            label="线索条数"
                            width="180"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="gzjls"
                            label="跟踪记录数"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="dds"
                            label="丢单数"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="cgs"
                            label="成功数"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="cgl"
                            label="成功率"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="ddl"
                            label="丢单率"
                        >
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import addBgImages from '../../utils';
export default {
    data() {
        return {
            tableData: [],
            tableName: [],
            tableXsts: [],
            tableCgs: [],
        };
    },
    mounted() {
        this.$nextTick(() => {
            this.salesanalysisYwy();
        });
    },
    methods: {
        closeClueStatistics() {
            this.$emit('closeClueStatistics');
        },
        clueStatisticsChildFun() {
            const myChart = this.$echarts.init(document.getElementById('clueStatisticsChildId'));
            const option = {
                grid: {
                    top: '18%',
                    left: '4%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true,
                },
                legend: {
                    itemWidth: 8,
                    itemHeight: 8,
                    data: ['线索条数', '成功数'],
                    x: 'right',
                    padding: [20, 50, 0, 0],
                },
                xAxis: {
                    data: this.tableName,
                    type: 'category',
                    axisLabel: {
                        color: '#666666',
                        fontSize: 10,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLine: {
                        lineStyle: {
                            color: '#D7D7D7',
                        },
                    },
                    splitLine: { show: false },
                },
                yAxis: {
                    name: '单位(个)',
                    nameTextStyle: {
                        padding: [0, 0, -20, 50], // 四个数字分别为上右下左与原位置距离
                        color: '#979EA7',
                        fontSize: '10',
                    },
                    axisLabel: {
                        color: '#666666',
                        fontSize: 10,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLine: {
                        lineStyle: {
                            color: '#D7D7D7',
                        },
                    },
                    splitLine: {
                        lineStyle: {
                            color: '#D7D7D7',
                            type: 'dashed',
                        },
                    },
                },
                series: [
                    {
                        name: '线索条数',
                        data: this.tableXsts,
                        type: 'bar',
                        barWidth: 30,
                        color: '#44A1F5',
                        label: {
                            show: true,
                            position: 'top',
                            fontFamily: 'QuartzLTStd',
                            padding: 5,
                            color: '#4D7DE0',
                            // backgroundColor: {
                            //     image: addBgImages(1, 'top'),
                            // },
                        },
                        itemStyle: {
                            normal: {
                                color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                                    offset: 0,
                                    color: '#3E78C3', // 0% 处的颜色
                                }, {
                                    offset: 1,
                                    color: '#44A1F5', // 100% 处的颜色
                                }], false),
                            },
                        },
                    },
                    {
                        name: '成功数',
                        data: this.tableCgs,
                        type: 'bar',
                        barWidth: 30,
                        color: '#F0AF56',
                        label: {
                            show: true,
                            position: 'top',
                            padding: 5,
                            fontFamily: 'QuartzLTStd',
                            color: '#EA8344',
                            // backgroundColor: {
                            //     image: addBgImages(4, 'top'),
                            // },
                        },
                        itemStyle: {
                            normal: {
                                color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                                    offset: 0,
                                    color: '#EA8243', // 0% 处的颜色
                                }, {
                                    offset: 1,
                                    color: '#F0AF56', // 100% 处的颜色
                                }], false),
                            },
                        },
                    },
                ],
            };
            myChart.setOption(option, true);
        },
        salesanalysisYwy() {
            this.$axios
                .get('/interfaceApi/report/salesanalysis/xsgz/ywy?')
                .then(res => {
                    this.tableData = res.list;
                    this.tableData.forEach(item => {
                        this.tableName.push(item.name);
                        this.tableXsts.push(item.xsts);
                        this.tableCgs.push(item.cgs);
                    });
                    this.clueStatisticsChildFun();
                })
                .catch(err => {
                    this.$message.error(err.ErrorCode.message);
                });
        },
    },
};

</script>
<style lang='stylus'>
.clue-statistics-child
    height 100%
    position fixed
    top 0
    left 0
    right 0
    bottom 0
    background rgba(0,0,0,0.4)
    z-index 99
    display flex
    justify-content center
    align-items center
    .clue-statistics-box-child
        width 90%
        background #fff
        .day-trend-head
            width 100%
            background #0C2C87
            text-align center
            color #fff
            padding .1rem 0;
            display flex
            justify-content space-between
            align-items center
            span
                font-size .2rem
                &:last-child
                    margin-right .2rem
                    cursor pointer
        .clue-statistics-one
            display flex
            flex-direction column
            justify-content space-between
            border 1px solid #D7D7D7
            h1
                color #333333
                font-size .18rem
                font-weight 800
                padding .2rem
                &:before
                    content '`'
                    width .06rem
                    height .2rem
                    background #4D7DE0
                    color #4D7DE0
                    margin-right .1rem
        .clue-statistics-two
            margin-top .2rem
            .el-table
                width 100%
                .el-table__footer-wrapper tbody tr td div
                    color #C9661E !important
                .el-table__header-wrapper thead tr th
                    background #EDF0F5
                    color #022782
                th, td
                        padding .05rem 0
                        text-align center
                        div
                            font-size .16rem
                    .el-table__footer tbody tr
                        td
                            background none
                            div
                                color #823802
                        td:first-child
                            background #F5F2ED
                            div
                                font-weight 800
</style>