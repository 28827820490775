<template>
    <div class="clue-statistics">
        <div class="clue-statistics-box">
            <div class="clue-statistics-one">
                <h1>线索跟踪成功率</h1>
                <div style="width:100%;height:270px;" id="clueStatisticsId"></div>
            </div>
            <!-- 导出excel -->
            <exportDom :dom-id="'#statisticsDom'" :excel-name="'线索跟踪统计'"></exportDom>
            <div class="clue-statistics-two">
                <el-table
                    id="statisticsDom"
                    :data="tableData"
                    border
                    style="width: 100%"
                    max-height="300"
                    show-summary
                    :summary-method="getSummaries"
                >
                    <el-table-column
                        prop="name"
                        label="业务员"
                        width="180"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="xsts"
                        label="线索条数"
                        width="180"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="gzjls"
                        label="跟踪记录数"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="dds"
                        label="丢单数"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="cgs"
                        label="成功数"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="cgl"
                        label="成功率%"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="ddl"
                        label="丢单率%"
                    >
                    </el-table-column>
                </el-table>
            </div>
            <clueStatistics v-if="showClueStatistics" @closeClueStatistics="closeClueStatistics"></clueStatistics>
        </div>
    </div>
</template>

<script>
import exportDom from './components/export';
import clueStatistics from './components/clueStatistics/clueStatisticsChild';
// import addBgImages from './utils';
export default {
    data() {
        return {
            showClueStatistics: false,
            tableData: [],
            tableName: [],
            tableXsts: [],
            tableCgs: [],
        };
    },
    components: {
        clueStatistics,
        exportDom,
    },
    mounted() {
        this.$nextTick(() => {
            this.salesanalysisYwy();
        });
    },
    methods: {
        getSummaries(param) {
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = '合计';
                    return;
                }
                const values = data.map(item => Number(item[column.property]));
                if (!values.every(value => isNaN(value))) {
                    sums[index] = values.reduce((prev, curr) => {
                        const value = Number(curr);
                        if (!isNaN(value)) {
                            return prev + curr;
                        }
                        return prev;

                    }, 0);
                    if (column.label === '成功率%') {
                        sums[index] = Number((sums[4] / sums[1] * 100).toFixed(2));
                    } else if (column.label === '丢单率%') {
                        sums[index] = Number((sums[3] / sums[1] * 100).toFixed(2));
                    }
                } else {
                    sums[index] = 'N/A';
                }
            });
            return sums;
        },
        closeClueStatistics() {
            this.showClueStatistics = false;
        },
        clueStatisticsFun() {
            const myChart = this.$echarts.init(document.getElementById('clueStatisticsId'));
            const option = {
                grid: {
                    top: '18%',
                    left: '4%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true,
                },
                legend: {
                    itemWidth: 8,
                    itemHeight: 8,
                    data: ['线索条数', '成功数'],
                    x: 'right',
                    padding: [20, 50, 0, 0],
                },
                xAxis: {
                    data: this.tableName,
                    type: 'category',
                    axisLabel: {
                        color: '#666666',
                        fontSize: 10,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLine: {
                        lineStyle: {
                            color: '#D7D7D7',
                        },
                    },
                    splitLine: { show: false },
                },
                yAxis: {
                    name: '单位(个)',
                    nameTextStyle: {
                        padding: [0, 0, -20, 50], // 四个数字分别为上右下左与原位置距离
                        color: '#979EA7',
                        fontSize: '10',
                    },
                    axisLabel: {
                        color: '#666666',
                        fontSize: 10,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLine: {
                        lineStyle: {
                            color: '#D7D7D7',
                        },
                    },
                    splitLine: {
                        lineStyle: {
                            color: '#D7D7D7',
                            type: 'dashed',
                        },
                    },
                },
                series: [
                    {
                        name: '线索条数',
                        data: this.tableXsts,
                        type: 'bar',
                        barWidth: 15,
                        color: '#44A1F5',
                        label: {
                            show: true,
                            position: 'top',
                            fontFamily: 'QuartzLTStd',
                            padding: 5,
                            color: '#4D7DE0',
                            // backgroundColor: {
                            //     image: addBgImages(1, 'top'),
                            // },
                        },
                        itemStyle: {
                            normal: {
                                color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                                    offset: 0,
                                    color: '#3E78C3', // 0% 处的颜色
                                }, {
                                    offset: 1,
                                    color: '#44A1F5', // 100% 处的颜色
                                }], false),
                            },
                        },
                    },
                    {
                        name: '成功数',
                        data: this.tableCgs,
                        type: 'bar',
                        barWidth: 15,
                        color: '#F0AF56',
                        label: {
                            show: true,
                            position: 'top',
                            padding: 5,
                            fontFamily: 'QuartzLTStd',
                            color: '#EA8344',
                            // backgroundColor: {
                            //     image: addBgImages(4, 'top'),
                            // },
                        },
                        itemStyle: {
                            normal: {
                                color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                                    offset: 0,
                                    color: '#EA8243', // 0% 处的颜色
                                }, {
                                    offset: 1,
                                    color: '#F0AF56', // 100% 处的颜色
                                }], false),
                            },
                        },
                    },
                ],
            };
            myChart.setOption(option, true);
        },

        salesanalysisYwy() {
            this.$axios
                .get('/interfaceApi/report/salesanalysis/xsgz/ywy?')
                .then(res => {
                    this.tableData = res.list;
                    this.tableData.forEach(item => {
                        this.tableName.push(item.name);
                        this.tableXsts.push(item.xsts);
                        this.tableCgs.push(item.cgs);
                    });
                    this.clueStatisticsFun();
                })
                .catch(err => {
                    this.$message.error(err.ErrorCode.Message);
                });
        },
    },
};

</script>
<style lang='stylus'>
.clue-statistics-box
    width 100%
    padding .2rem
    background #fff
    height 100%
    .clue-statistics-one
        display flex
        flex-direction column
        justify-content space-between
        border 1px solid #D7D7D7
        h1
            color #333333
            font-size .18rem
            font-weight 800
            padding .2rem
            &:before
                content '`'
                width .06rem
                height .2rem
                background #4D7DE0
                color #4D7DE0
                margin-right .1rem
    .clue-statistics-two
        margin-top .1rem
        .el-table
            width 100%
            .el-table__footer-wrapper tbody tr td div
                color #C9661E !important
            .el-table__header-wrapper thead tr th
                background #EDF0F5
                color #022782
            th, td
                    padding .05rem 0
                    text-align center
                    div
                        font-size .16rem
                .el-table__footer tbody tr
                    td
                        background none
                        div
                            color #823802
                    td:first-child
                        background #F5F2ED
                        div
                            font-weight 800
    .export
        margin-top .1rem
        height .35rem
        .export_btn
            float right
            border: none;
            height: 0.36rem;
            font-size: 0.14rem;
            min-width: 0.36rem;
            line-height: 0.36rem;
            cursor: pointer;
            padding: 0 0.2rem;
            border-radius: 2px;
            background: #f1f4f7;
            color: #2978ff;
            text-align: center;
</style>
